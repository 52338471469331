<template>
  <div class="img-container">

    <div class="img-slide-set">
        <div class="slide-button" @click="moveLeft()">
            <span class="slide-button-left" ></span>
        </div>
        <div class="slide-wraper">

            <div class="slide-box" :style="imgSlide">

                <img
                    class="slide-img"
                    v-for="(url, index) in imgUrl" 
                    :key="index" 
                    :src="url"
                >

            </div>
        </div>

        <div class="slide-button" @click="moveRight()">
            <span class="slide-button-right" ></span>
        </div>
    </div>

    <div class="slide-offset-box">
        <div 
            class="slide-offset-wraper"
            v-for="(url, index) in displayImg" 
            :key="index" 
            @click="moveSlide(index)"
        >
            <span 
                class="slide-offset" 
                :class="{ active : isActive(index) }"
            >
            </span>
        </div>
    </div>

  </div>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue'
export default {
    setup(){
        
        const imgUrl = ref([
            require('@/assets/imgSlide/slide01.png'),
            require('@/assets/imgSlide/slide02.png'),
            require('@/assets/imgSlide/slide03.jpg'),
            require('@/assets/imgSlide/slide04.jpg'),
            require('@/assets/imgSlide/slide05.jpg')
        ]);

        const currentSlide = ref(1); // 배열에서 이미지 복제가 일어나므로 복제된 이미지가 아닌 다음 이미지로 초기화
        const isClonedSlideFlag = ref(false); // 복제된 슬라이드와 일반 슬라이드에서 출발지정 확인
        
        const transform = () => {
            if(isClonedSlideFlag.value) {
                if(currentSlide.value === 1 || currentSlide.value === imgUrl.value.length - 2){
                    return 'none';
                } else {
                    isClonedSlideFlag.value = false; // else문 진입후 다시 초기화
                    return 'transform 0.9s ease';
                }
            } else {
                return 'transform 0.9s ease';
            }
        }

        const imgSlide = computed(() => {

            let offset = currentSlide.value * 100;
            
            return {
                transform: `translateX(-${offset}%)`,
                transition: transform()
            };
        });

        const moveRight = () => {
            if (currentSlide.value < imgUrl.value.length - 1) {

                currentSlide.value++;

            } else {
                    
                    isClonedSlideFlag.value = true;
                    currentSlide.value = 1

                setTimeout(()=> {
                    currentSlide.value = 2
                }, 0) 

            }
        };

        const moveLeft = () => {
            if (currentSlide.value > 0) {

                currentSlide.value--;

            } else {
                isClonedSlideFlag.value = true;
                currentSlide.value = imgUrl.value.length - 2; // 원본 마지막 이미지로 순간 이동
                setTimeout(() => {
                    currentSlide.value = imgUrl.value.length - 3;
                }, 0)
            }
        };

        const displayImg = computed(() => {
            return imgUrl.value.slice(1, imgUrl.value.length - 1);
        });

        const moveSlide = (index) => {
            currentSlide.value = index+1;
        }

        const autoSlide = () => {
            setInterval(moveRight, 3000)
        }

        const isActive = (index) => {
            
            if (currentSlide.value === 1 || currentSlide.value === imgUrl.value.length - 1) {

                return index === 0;

            } else {
                return currentSlide.value - 1 === index;
            }
        }

        onMounted(() => {
            imgUrl.value.unshift(imgUrl.value[imgUrl.value.length - 1]); // 배열의 시작에 마지막 이미지 복제
            imgUrl.value.push(imgUrl.value[1]); // 배열의 끝에 첫 번째 이미지 복제 (0번째 인덱스는 이미 이동되었으므로 1번째 인덱스)
            autoSlide();
        });

        onUnmounted(() => {
            clearInterval();
        })

        return{
            imgUrl,
            imgSlide,
            moveLeft,
            moveRight,
            displayImg,
            moveSlide,
            isActive
        }
    }
}
</script>

<style scoped>

.img-container {

    display: flex;
    flex-direction: column;

    justify-items: center;
    align-items: center;

}

.img-slide-set {
    display: flex;
    position: relative;
    
    width: 100%;

    margin: 0.52083vw; /* 10px / 1920px * 100 */
    align-items: center;
    justify-content: center;

}

.slide-wraper{
    width: 41.6666vw; /*800px;*/ /*36.979vw;*/ /*710px;*/ /*46.875vw;*/ /* 900px / 1920px * 100 */
    height: 25.7203125vw; /* 493.83px; */ /*22.8281vw; */ /*438.3px;*/ /*28.9354vw;*/ /* 555.56px / 1920px * 100 */
    overflow: hidden;
}

.slide-box {

    display: flex;
    flex-direction: row;

}

.slide-img{
    width: 41.6666vw; /*46.875vw;*//*36.979vw;*/ /* 900px / 1920px * 100 */
    height: 25.7203125vw; /*28.9354vw;*/ /*22.8281vw;*/ /* 555.56px / 1920px * 100 */
    object-fit: cover;
}

.slide-button {
    display: flex;

    width: 1.5625vw; /* 30px / 1920px * 100 */
    height: 4.16667vw; /* 80px / 1920px * 100 */
    border: 1px solid rgba(170, 170, 170, 0.3);
    border-radius: 2px;
    background-color: rgba(170, 170, 170, 0.3);

    margin: 0 0.78125vw 0 0.78125vw; /* 15px / 1920px * 100 */

    justify-content: center;
    align-items: center;
    text-align: center;

    cursor: pointer;
    z-index: 2;
}

.slide-button-right,
.slide-button-left{
    width: 0;
    height: 0;

}

.slide-button:hover{
    filter: invert();
}

.slide-button-left{

    border-top: 0.5208vw solid transparent; /* 10px / 1920px * 100 */ 
    border-bottom: 0.5208vw solid transparent;
    border-left: 0.5208vw solid transparent;
    border-right: 0.5208vw solid white;

    margin-right: 0.5208vw;
    
}

.slide-button-right{
    border-top: 0.5208vw solid transparent;
    border-bottom: 0.5208vw solid transparent;
    border-right: 0.5208vw solid transparent;
    border-left: 0.5208vw solid white;

    margin-left: 0.5208vw;
}

.slide-offset-box{
    display: flex;
}

.slide-offset-wraper {
    display: flex;

    width: 1.5625vw; /* 30px / 1920px * 100 */
    height: 1.5625vw;
    padding: 0.2604vw; /* 5px / 1920px * 100 */

    justify-content: center;
    align-items: center;
    text-align: center;

}

.slide-offset {
    display: block;

    width: 0.5208vw; /* 10px / 1920px * 100 */ 
    height: 0.5208vw;

    background-color: #FFF; 
    border: gray 2px solid;
    border-radius: 100%;    

    opacity: 0.6;
    transition: 1.3s ease-in-out all;
    cursor: pointer;
    text-align: center;
}

.slide-offset.active {
    width: 2.60417vw; /* 50px / 1920px * 100 */ 

    background-color: grey; 
    border-radius: 1.041667vw; /* 20px / 19020px * 100 */
    opacity: 1;
}

.slide-offset:hover {
    opacity: 1;
    transform: scale(1.2);

    background-color: #FFF; 
}

</style>