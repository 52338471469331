const AdminReply = [
    {
        path: '/admin/request/adminUnansweredReply',
        name: 'AdminUnansweredReply',
        component: () => import(/* webpackChunkName: "adminReply" */ '@/pages/admin/adminReply/adminUnansweredReply.vue')
    },
    {
        path: '/admin/request/adminTotalReply',
        name: 'AdminTotalReply',
        component: () => import(/* webpackChunkName: "adminReply" */ '@/pages/admin/adminReply/adminTotalReplyList.vue')
    },
    // {
    //     path: '/admin/request/adminTotalReply',
    //     name: 'AdminReplyRead',
    //     component: () => import(/* webpackChunkName: "adminReply" */ '@/pages/admin/adminRequest/adminTotalReplyList.vue')
    // },
]

export default AdminReply;