<template>
    <div id="app">
        <!-- <Splash v-if = splashState @click="clickOutSide" /> -->
        <div class="flex-container">
            <Header />
            <MainSection />
            <Footer />
        </div>
    </div>
</template>

<script>
import Header from './components/header.vue';
import MainSection from './components/mainsection.vue';
import Footer from './components/footer.vue';
// import Splash from './components/splash/splash.vue';
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
    components: {
        Header,
        MainSection,
        Footer,
        // Splash,
    },
    
    setup(){
        // axios.defaults.baseURL = 'http://localhost:8080/api/';
        // axios.defaults.baseURL = 'http://3.37.238.213/api/';
        axios.defaults.baseURL = 'http://www.prottype.co.kr/api/';

        axios.defaults.headers.common['Authorization'] = localStorage.getItem('accessToken');
        axios.defaults.withCredentials = true;

        const splashState = ref(JSON.parse(sessionStorage.getItem('splashState')));

        onMounted(() => {
            if(!splashState.value){
                sessionStorage.setItem('splashState', JSON.stringify(true));
            }

        setTimeout(() => {
            sessionStorage.setItem('splashState', JSON.stringify(false));
            splashState.value = JSON.parse(sessionStorage.getItem('splashState'));
        }, 10000);
    });

        const clickOutSide = () => {
            sessionStorage.setItem('splashState', JSON.stringify(false));
            splashState.value = JSON.parse(sessionStorage.getItem('splashState'));
        }

        /* self.crypto.randomUUID()가 배포했을 때 되지 않아서 사용하게된 난수 생성 함수 */
        function generateUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        const visited = () => {
            if(getVisitorCookie()) {
                return;
            }else {
                setVisitorCookie()
                countDailyVisitor()
                return;
            }
        }

        const setVisitorCookie = () => {
            const name = generateUUID();
            const now = new Date();
            const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            const expires = tomorrow.toUTCString();

            document.cookie = `prottype-visitor-${name}=visited; expires=${expires}; path=/`
        }

        const getVisitorCookie = () => {
            let cookieArr = document.cookie.split(';')
            for (let i = 0; i < cookieArr.length; i++) {
                let cookie = cookieArr[i].trim();
                if (cookie.startsWith('prottype-visitor-')) {
                    return true; 
                }
            }
            return false;
        }

        const countDailyVisitor = async () => {
            await axios.get('/account/countDailyVisitor')
        }

        visited()

        return{
            splashState,
            clickOutSide,
        }
    }
};

</script>

<style>
@font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Regular.otf') format('opentype');
}
html{
    height: 100%;
    font-family: 'Pretendard';
}
body{
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;

    overflow-x: hidden;
}

.flex-container{

    display: flex;
    flex-direction: column;

    min-height: 100vh;
    width: 100%;
}

/* 스크롤바 지우기 */

/* Chrome, Safari, Opera */
html::-webkit-scrollbar {
    /* display: none; */
}

/* Firefox, IE, Edge */
html {
    /* scrollbar-width: none; */
    /* -ms-overflow-style: none; */
}

</style>
