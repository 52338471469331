import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/home.vue';
import UserPortfolio from './user/userPortfolioRoutes';
import UserProcess from './user/userProcessRoutes';
import UserAccount from './user/userAccountRoutes';
import UserNotice from './user/userNoticeRoutes';
import UserRequest from './user/userRequestRoutes';
import AdminNotice from './admin/adminNoticeRoutes';
import AdminHome from '../pages/admin/adminHome.vue';
import AdminMain from '../pages/admin/adminMain.vue';
import AdminPortfolio from './admin/adminPortfolioRoutes';
import UserReply from './user/userReplyRoutes';
import AdminRequest from './admin/adminRequestRoutes';
import AdminAccount from './admin/adminAccountRoutes';
import AdminReply from './admin/adminReplyRoutes'
import CheckToken from '@/axioses/checkToken';
import Terms from '@/pages/agreement/terms';
import Privacy from '@/pages/agreement/privacy';
import store from '@/store';


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/admin',
        component: AdminHome, // AdminHome을 부모 컴포넌트로 사용
        beforeEnter: async (to, from, next) => {
            try {
                const tokenRole = await CheckToken.checkToken();

                if (tokenRole) {
                    store.commit('adminNavi', true);
                    next();
                } else {
                    alert('권한이 없습니다.');
                    store.commit('adminNavi', false);
                    next('/user/account/login');
                }
            } catch {
                store.commit('adminNavi', false);
                next('/user/account/login');
            }
        },
        children: [
            {
                path: '', // /admin에 해당
                name: 'AdminMain',
                component: AdminMain
            },
            ...AdminNotice,
            ...AdminPortfolio,
            ...AdminRequest,
            ...AdminAccount,
            ...AdminReply,
            // 다른 /admin 하위 경로 추가
        ]
    },
    ...UserPortfolio,
    ...UserProcess,
    ...UserNotice,
    ...UserAccount,
    ...UserRequest,
    ...UserReply,
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload();
    }
});
export default router;