const AdminNotice = [
    {
        path: '/admin/account',
        name: 'AdminAccount',
        component: () => import(/* webpackChunkName: "adminAccount" */ '@/pages/admin/adminAccount/adminAccount.vue')
    },
    {
        path: '/admin/account/accountInfo',
        name: 'AdminAccountInfo',
        component: () => import(/* webpackChunkName: "adminAccount" */ '@/pages/admin/adminAccount/adminAccountInfo.vue')
    },

]

export default AdminNotice;