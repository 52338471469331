<template>
    <div>
        <div v-if="!adminMode" class="header-container">
            <!-- <NavigationBarAdmin v-if="$store.getters.getopenAdminNavi" /> -->
            <NavigationBarOriginal 
                v-if="!choiceNevi"
            />
            <NavigationBarShort
                v-if="choiceNevi"
            />
            <!--<NavigationBarAdmin /> -->
        </div>
    </div>
    <div> 
        <div v-if="adminMode" class="header-container">
            <NavigationBarAdmin />
        </div>
    </div>
</template>

<script>
import NavigationBarOriginal from './navigationBar/navigationBarOriginal.vue';
import NavigationBarAdmin from './navigationBar/navigationBarAdmin.vue';
import checkToken from '@/axioses/checkToken';
import NavigationBarShort from './navigationBar/navigationBarShort.vue';
import { onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
// import store from '@/store';

export default {
    components : {
                NavigationBarOriginal,
                NavigationBarAdmin,
                NavigationBarShort,
    },
    setup () {

        const route = useRoute();
        const store = useStore()

    const checkLogin = async () => {
        await checkToken.checkToken()
    }

    const windowWidth = ref(window.innerWidth);
    const choiceNevi = ref(false);

    const checkWindow = () => {
        windowWidth.value = window.innerWidth;
        
        choiceNevi.value = windowWidth.value < 1440;
    }

    const adminMode = ref(false);

    watch(() => route.path, (newVal, oldVal) => {
        
        if(newVal != oldVal){
            if(newVal.startsWith('/admin') && store.getters.getadminState){
                adminMode.value = true;
            } else {
                adminMode.value = false;
            }
        }
    })


    onBeforeMount(() =>{
        checkWindow()
        checkLogin()
    })

    onMounted(() => {
        window.addEventListener('resize', checkWindow);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', checkWindow);
    });

        return {
            choiceNevi,
            adminMode
        }
    }
}
</script>

<style scoped>
.header-container {
    position: relative;
    width: 100%;
    position: fixed;

    z-index: 3000;
}
</style>