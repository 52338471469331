const UserPortfolio = [
    {
        path: '/user/portfolio',
        name: 'UserPortfolio',
        component: () => import(/* webpackChunkName: "portfolio" */ '@/pages/user/userPortfolio/userPortfolio')
    },
    {
        path: '/user/portfolio/read/:portfolioNo',
        name: 'UserPortfolioRead',
        component: () => import(/* webpackChunkName: "portfolio" */ '@/pages/user/userPortfolio/userPortfolioRead')
    },
]

export default UserPortfolio;