<template>
  <div class="navigationBar" :style="navigationBarInvertColor">
    <ul class="navigationBar-main">
        <li class="navigationBar-logo">
            <router-link :to="{name: 'Home'}">
                <img src="@/assets/prottypeLogoNavi.svg">
            </router-link>
        </li>

        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu portfolio">
            <router-link 
                :to="{name: 'UserPortfolio'}"
                                    @mouseover="changeNeviMenu('portfolio', 'Portfolio')" 
                    @mouseleave="resetNeviMenu('portfolio', '포트폴리오')"
            >
                

                    {{ neviMenu.portfolio }}

            </router-link>
        </li>
        
        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu process">
            <router-link :to="{name: 'UserProcess'}">
                <span 
                    @mouseover="changeNeviMenu('process', 'Process')" 
                    @mouseleave="resetNeviMenu('process', '공정소개')"
                >
                    {{ neviMenu.process }}
                </span>
            </router-link>
        </li>

        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu notice">
            <router-link :to="{name: 'UserNotice'}">
                <span 
                    @mouseover="changeNeviMenu('notice', 'Notice')" 
                    @mouseleave="resetNeviMenu('notice', '공지사항')"
                >
                    {{ neviMenu.notice }}
                </span>
            </router-link>
        </li>
        
        <li><span class="navigationBar-separator"></span></li>

        <li class="navigationBar-menu request">
            <router-link :to="{name: 'UserRequest'}">
                <span 
                    @mouseover="changeNeviMenu('request', 'Request')" 
                    @mouseleave="resetNeviMenu('request', '견적문의')"
                >
                    {{ neviMenu.request }}
                </span>
            </router-link>
        </li>

        <li><span class="navigationBar-separator"></span></li>

        <li class="navigationBar-hambergerMenu">
            <span class="navigationBar-hambergerMenu-icon" @mouseover="showDropdown" @mouseleave="hideDropdown" @click="toggleDropdownPin">☰</span>

            <ul class="navigationBar-dropdown" v-show="dropdownVisible" @mouseenter="showDropdown" @mouseleave="hideDropdown">
                <li class="navigationBar-dropMenu-siginbox" v-if="!$store.state.signinStatus">
                    <router-link 
                        :to="{name: 'UserAccountLogin'}" 
                        @click="hideDropdown"
                        @mouseover="changeNeviMenu('signin', 'Sign In')" 
                        @mouseleave="resetNeviMenu('signin', '로그인')"
                    >
                        {{ neviMenu.signin }}
                    </router-link>
                    <router-link 
                        :to="{name: 'UserRegistration'}" 
                        @click="hideDropdown"
                        @mouseover="changeNeviMenu('signup', 'Sign Up')" 
                        @mouseleave="resetNeviMenu('signup', '회원가입')" 
                    >
                        {{ neviMenu.signup }}
                    </router-link>
                </li>   
                <li class="navigationBar-dropMenu-siginbox" v-else>
                    <router-link 
                        :to="{name: 'UserInfo'}" 
                        @click="hideDropdown"
                        @mouseover="changeNeviMenu('myinfo', 'My Info')" 
                        @mouseleave="resetNeviMenu('myinfo', '나의정보')"
                    >
                        {{ neviMenu.myinfo }}
                    </router-link>
                    <a 
                        @click="logout"
                        @mouseover="changeNeviMenu('signout', 'Sign Out')" 
                        @mouseleave="resetNeviMenu('signout', '로그아웃')"
                    >
                        {{ neviMenu.signout }}
                    </a>
                    <a
                        v-if="admin" 
                        @click="openModal"
                        @mouseover="changeNeviMenu('adminpage', 'Admin Page')" 
                        @mouseleave="resetNeviMenu('adminpage', '관리하기')"
                    >
                        {{ neviMenu.adminpage }}
                    </a>
                </li>
            </ul>
        </li>
    </ul>

    <CheckPassword @success = 'moveAdminPage' />
  </div>
</template>

<script>
import accountModule from '@/axioses/accountModule'
import { ref, watch, onMounted, onUnmounted } from 'vue';
import CheckPassword from '../checkPassword/checkPassword.vue';
import store from '@/store';
import router from '@/router';
import checkToken from '@/axioses/checkToken';

export default {
    components: {
        CheckPassword,
    },

setup(){
    const admin = ref(false);
    const neviMenu = ref({
        portfolio: '포트폴리오',
        process: '공정소개',
        notice: '공지사항',
        request: '견적문의',
        signin: '로그인',
        signup: '회원가입',
        signout: '로그아웃',
        myinfo: '나의정보',
        adminpage: '관리하기'
    });

    const logout = async () => {
        await accountModule.logout();
        admin.value = false;
    }

    const checkAdmin = async () => {
        try {
            const result = await checkToken.checkToken()
            store.commit('checkAdmin',result);
            admin.value = store.state.admin;
        } catch {
            alert("페이지를 새로고침 합니다.");
            router.push("/");
        }
    }

    const changeNeviMenu = (menu, newValue) => {
        neviMenu.value[menu] = newValue;
    };

    const resetNeviMenu = (menu, originalValue) => {
        neviMenu.value[menu] = originalValue;
    };

    const openModal = () => {
        store.commit('checkPasswordModalStateChange', true);
    }

    const moveAdminPage = (success) => {
        if(success){
            router.push('/admin');
        }
    }

    const dropdownVisible = ref(false);
    const dropdownPinned = ref(false);

    const showDropdown = () => {
        dropdownVisible.value = true;
        dropdownPinned.value = false;
    }

    const toggleDropdownPin = () => {
        dropdownVisible.value = true;
        dropdownPinned.value = !dropdownPinned.value; // 드롭다운 고정 상태를 토글
    }

    const hideDropdown = () => {
        if (!dropdownPinned.value) {
            dropdownVisible.value = false;
        }
    }

    const navigationBarInvertColor = ref();

    const invert = () => {

        if(store.state.currentHomeSection != null && store.state.currentHomeSection == 0) {
            navigationBarInvertColor.value = {
                'background-color' : 'rgba(180, 180, 180, 0.3)',
                'border-bottom' : '2px dashed rgba(255, 255, 255)',
                'filter' : 'invert(100%)',
            }
        } else {
            navigationBarInvertColor.value = {
                'border-bottom': '2px solid rgba(0, 0, 0)'
            }
        }
    }

    watch(() => store.state.signinStatus,
        () => {
            checkAdmin()
        }
    )

    watch(() => store.state.currentHomeSection,
        () => {
            invert();
        }
    )

    onMounted(() => {
        checkAdmin()
        invert()
    })

    onUnmounted(() => {
        checkAdmin()
    })

    return{
        logout,
        neviMenu,
        changeNeviMenu,
        resetNeviMenu,
        admin,
        openModal,
        moveAdminPage,
        dropdownVisible,
        showDropdown,
        hideDropdown,
        dropdownPinned,
        toggleDropdownPin,
        navigationBarInvertColor
    }
}
}
</script>

<style scoped>
@import '@/assets/css/navigation/navigationBarShort.css';
</style>