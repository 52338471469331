<template>
  <div :style="navigationBarInvertColor" class="navigationBar-container">
    <ul>
        <li class="navigationBar-logo">
            <router-link :to="{name: 'Home'}">
                <img src="@/assets/prottypeLogoNavi.svg">
            </router-link>
        </li>

        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu portfolio">
            <router-link :to="{name: 'UserPortfolio'}">
                <span 
                    @mouseover="changeNeviMenu('portfolio', 'Portfolio')" 
                    @mouseleave="resetNeviMenu('portfolio', '포트폴리오')"
                >
                    {{ neviMenu.portfolio }}
                </span>
            </router-link>
        </li>
        
        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu process">
            <router-link :to="{name: 'UserProcess'}">
                <span 
                    @mouseover="changeNeviMenu('process', 'Process')" 
                    @mouseleave="resetNeviMenu('process', '공정소개')"
                >
                    {{ neviMenu.process }}
                </span>
            </router-link>
        </li>

        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu notice">
            <router-link :to="{name: 'UserNotice'}">
                <span 
                    @mouseover="changeNeviMenu('notice', 'Notice')" 
                    @mouseleave="resetNeviMenu('notice', '공지사항')"
                >
                    {{ neviMenu.notice }}
                </span>
            </router-link>
        </li>
        
        <li><span class="navigationBar-separator"></span></li>

        <li class="navigationBar-menu request">
            <router-link :to="{name: 'UserRequest'}">
                <span 
                    @mouseover="changeNeviMenu('request', 'Request')" 
                    @mouseleave="resetNeviMenu('request', '견적문의')"
                >
                    {{ neviMenu.request }}
                </span>
            </router-link>
        </li>
        
        <li><span class="navigationBar-separator"></span></li>
        <li class="navigationBar-menu siginbox" v-if="!$store.state.signinStatus">
            <router-link :to="{name: 'UserAccountLogin'}">
                <span
                    class="navigationBar-signin"
                    @mouseover="changeNeviMenu('signin', 'Sign In')" 
                    @mouseleave="resetNeviMenu('signin', '로그인')"
                >
                    {{ neviMenu.signin }}
                </span>
            </router-link>
            <router-link :to="{name: 'UserRegistration'}">
                <span
                    class="navigationBar-signup"
                    @mouseover="changeNeviMenu('signup', 'Sign Up')" 
                    @mouseleave="resetNeviMenu('signup', '회원가입')"    
                >
                    {{ neviMenu.signup }}
                </span>
            </router-link>
        </li>

        <li class="navigationBar-menu siginbox" v-else>
            <router-link :to="{name: 'UserInfo'}"
                @mouseover="changeNeviMenu('myinfo', 'My Info')" 
                @mouseleave="resetNeviMenu('myinfo', '나의정보')"
            >
                <span class="navigationBar-signed" >{{ neviMenu.myinfo }}</span>
            </router-link>
            <a
                @mouseover="changeNeviMenu('signout', 'Sign Out')" 
                @mouseleave="resetNeviMenu('signout', '로그아웃')"
            >
                <span class="navigationBar-signed" @click="logout">{{ neviMenu.signout }}</span>
            </a>
            <a
                @mouseover="changeNeviMenu('adminpage', 'Admin Page')" 
                @mouseleave="resetNeviMenu('adminpage', '관리하기')"
            >
                <span class="navigationBar-signed" v-if="admin" @click="openModal">{{ neviMenu.adminpage }}</span>
            </a>
        </li>

    </ul>
    <CheckPassword @success = 'moveAdminPage' />
  </div>
</template>

<script>
import accountModule from '@/axioses/accountModule'
import { onMounted, onUnmounted, ref, watch } from 'vue';
import CheckPassword from '../checkPassword/checkPassword.vue';
import store from '@/store';
import router from '@/router';
import checkToken from '@/axioses/checkToken';

export default {
    components: {
        CheckPassword,
    },

setup(){
    const admin = ref(false);
    const neviMenu = ref({
        portfolio: '포트폴리오',
        process: '공정소개',
        notice: '공지사항',
        request: '견적문의',
        signin: '로그인',
        signup: '회원가입',
        signout: '로그아웃',
        myinfo: '나의정보',
        adminpage: '관리하기'
    });

    const logout = async () => {
        await accountModule.logout();
        admin.value = false;
    }
    const checkAdmin = async () => {
        try {
            const result = await checkToken.checkToken()
            store.commit('checkAdmin',result);
            admin.value = store.getters.getadminState;
        } catch {
            alert("페이지를 새로고침 합니다.");
            router.push("/");
        }
    }

    const changeNeviMenu = (menu, newValue) => {
        neviMenu.value[menu] = newValue;
    };

    const resetNeviMenu = (menu, originalValue) => {
        neviMenu.value[menu] = originalValue;
    };

    const openModal = () => {
        store.commit('checkPasswordModalStateChange', true);
    }

    const moveAdminPage = (success) => {
        if(success){
            router.push('/admin');
        }
    }

    const navigationBarInvertColor = ref();

    const invert = () => {

        if(store.state.currentHomeSection != null && store.state.currentHomeSection == 0) {
            navigationBarInvertColor.value = {
                'background-color' : 'rgba(180, 180, 180, 0.3)',
                'border-bottom' : '2px dashed rgba(255, 255, 255)',
                'filter' : 'invert(100%)',
            }
        } else {
            navigationBarInvertColor.value = {
                'border-bottom': '2px solid rgba(0, 0, 0)'
            }
        }
    }

    onMounted(() => {
        checkAdmin()
        invert()
    })

    onUnmounted(() => {
        checkAdmin()
    })

    watch(() => store.state.signinStatus,
        () => {
            checkAdmin()
        }
    )

    watch(() => store.state.currentHomeSection,
        () => {
            invert();
        }
    )

    return{
        logout,
        neviMenu,
        changeNeviMenu,
        resetNeviMenu,
        admin,
        openModal,
        moveAdminPage,
        navigationBarInvertColor,
    }
}
}
</script>

<style scoped>
@import '@/assets/css/navigation/navigationBarOriginal.css';
</style>