<template>
  <div class="container">
    <div class="privacy-contents">
  <p class="privacy-contents-headline">개인정보 처리 방침</p>
  <hr>
  <p>
    <strong>
    본 약관은 PROTTYPE.(프롯타입)과 PROTTYPE.(이하 "당사")이 운영하는 인터넷 웹사이트 www.prottype.com (이하 "사이트")을 통해 제공하는 인터넷 관련 서비스(이하 "서비스")의 이용조건 및 절차에 관련하여 회사와 이용자간의 권리와 의무 및 기타 필요한 사항을 규정함을 목적으로 합니다.
    </strong>
    <br><br>
    당사는 고객님의 개인 정보를 소중하게 생각하며, 고객님의 개인정보를 보호하기 위하여 항상 최선을 다해 노력하고 있습니다. 당사의 홈페이지는 이를 위해 개인정보보호 관련 주요 법률인「개인정보보호법」을 준수하고 있습니다. 회사의 홈페이지는 개인 정보 처리방침을 제정하여 이를 사이트 내에 공개하고 고객님이 언제나 용이하게 열람할 수 있도록 함으로써, 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되는지에 대하여 알려드립니다.
    홈페이지의 개인정보처리방침은 관련 법률 및 지침의 변경 또는 내부 운영 방침의 변경에 따라 변경될 수 있습니다. 귀하께서는 당사 홈페이지에 방문하셔서 개인정보처리방침을 수시로 확인하여 주시기 바랍니다 회사의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.
  </p>
  <p>
    <strong>1. 수집하는 개인정보 항목 및 수집방법</strong>
    <br><br>
    "개인정보"이라 함은 생존하는 개인에 관한 정보로서 해당 정보에 포함되어 있는 성명, 이메일, 전화 등의 사항에 의하여 해당 개인을 식별할 수 있는 정보(해당 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)을 말합니다.
    당사는 이용자들에게 맞춤식 서비스를 비롯한 보다 더 향상된 양질의 서비스를 제공하기 위하여 이용자 개인의 정보를 수집하고 있습니다.
    당사는 이용자의 사전 동의 없이는 이용자의 개인 정보를 함부로 공개하지 않습니다.
    민감한 (사상, 신념, 과거의 병력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는) 개인정보를 수집하지 않습니다.
  </p>
  <p>
  
  <strong>[수집하는 개인정보 항목]</strong>
    <br><br>
    당사는 캠페인/이벤트, 홈페이지, 제작 서비스 제공을 위해 아래와 같은 개인정보를 수집하고 있습니다.
    <br><br>
    필수 정보 : 이름, 이메일, 전화번호
    선택 항목 : 소속/단체명, 3D설계도안 등 당사에서 요청하는 정보
    <br>
  </p>
  <p>
    <strong>[수집 방법]</strong>
    <br><br>
    당사는 고객이 업계 간행물 광고 및/또는 보도 자료를 통해 제품 정보를 요청하는 경우,<br>
    문의 양식 및 당사 웹 사이트 이메일 링크를 통해 정보를 요청하는 경우,<br>
    박람회 및 컨퍼런스를 통해 정보를 요청하는 경우에 고객 정보를 수집합니다.
    <br><br>
    고객이 당사 웹 사이트를 검색하는 경우, 익명으로 검색할 수 있습니다.<br>
    일반적으로, 당사는 고객이 검색할 때 고객의 이메일 주소를 포함 하여 어떤 개인 정보도 수집하지 않습니다.<br>
    그러나, 고객의 브라우저에서 컴퓨터 유형 및 고객이 사용 중인 운영 체제를 자동으로 알려줍니다.<br>
    당사가 고객에게 전송하는 이메일 중 일부에는 사용자 클릭 URL이 포함되어 있습니다. 이러한 URL을 클릭하면 원하는 웹 사이트로 이동하기 전에 당사 웹 서버를 통과하게 됩니다.<br>
    당사는 고객이 특정 주제에 관심이 있는지 판단하고 당사 고객 커뮤니케이션의 효과를 측정할 수 있도록 사용자 클릭을 추적합니다.
  </p>
  <p>
    <strong>2. 개인정보의 수집 및 이용목적</strong>
  <br><br>
    당사가 고객에게 현재 시장 및 제품 정보를 제공하고, 당시 제품을 편리하게 확인할 수 있도록 하며, 우수한 서비스를 제공하기 위해 개인 정보를 수집하고 사용합니다. 또한, 당사는 신제품 발표, 특별 행사 및 고객에게 혜택을 제공한다고 간주되는 기타 정보에 대한 최신 내용을 고객에 게 알려드리기 위해 개인 정보를 사용합니다.
  </p>
  <p>
    <strong>3. 개인정보의 제공 및 공유</strong>
  <br><br>
    <strong>개인정보 공유</strong>
  <br><br>
    이용자들이 사전에 공개에 동의한 경우 이 경우도 고지 및 동의 받은 목적과 기간 내에서만 이용되며,
    본 개인정보취급방침에 명기한 사항에 따라 처리됩니다.
    법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 차와 방법에 따라 수사기관의 요구가 있는 경우
  </p>
  <p>
    <strong>4. 개인정보의 보유 및 이용기간</strong>
  <br><br>
    원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

    내부 방침에 의한 개인 정보 보유 사유

    보존 항목 : 이름, 회원ID, 이메일, 주소, 전화번호, 소속/단체명
    보존 이유 : 용역제공 및 장비유지/관리 서비스, 판매/마케팅 및 광고에 활용, 고객관리
    보존 기간 : 고객의 요청에 따라 개인정보 사용에 대한 동의를 철회할 때까지의 기간(1개월)

    관련법령에 의한 정보보유 사유

    상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 개인정보를 보관합니다. 이 경우 당사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

    보존 항목 : 당사의 상업장부와 영업에 관한 중요서류 및 전표 등에 관련된 정보 및 모든 거래에 관한 장부 및 증빙서류와 관련된 정보
    보존 이유 : 상법, 국세기본법, 법인세법
    보존 기간 : 10년 - 중요서류 / 5년 - 전표
  </p>
  <p>
    <strong>5. 개인정보의 파기절차 및 방법</strong>
  <br><br>
    이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
  <br><br>
    <b>파기절차</b>
  <br>
    샘플신청, 이벤트/캠페인 참여 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 (보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
  <br><br>
    <b>파기방법</b>
  <br>
    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
    전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
  </p>
  <p>
    <strong>6. 이용자 및 법정대리인의 권리와 그 행사방법</strong>
  <br><br>
  일반 이용자
  <br><br>
    이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
    이용자 혹은 만 14세 미만 아동의 개인정보 조회·수정을 위해서는 '마이페이지'의 '회원정보 수정'을 통하여 가능하며, 가입해지 시에는 '마이페이지'의 '회원탈퇴'를 클릭하여 본인 확인 절차를 거치신 후 탈퇴가 가능합니다. 혹은 개인정보 관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
    이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다. 회사는 이용자 혹은 법정대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용 할 수 없도록 처리하고 있습니다.
  </p>
  <p>
    <strong>7. 개인정보 자동수집 장치의 설치/운영 및 그 거부에 관한 사항</strong>
  <br><br>
    이용자들에게 특화된 맞춤서비스를 제공하기 위해서 당사는 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)이 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
  <br><br>
    <b>쿠키 등 사용 목적</b>
  <br>
    당사는 이용자들에게 적합하고 보다 유용한 서비스를 제공하기 위해서 쿠키를 이용하여 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심 분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스를 만들어 제공할 수 있습니다.
  <br><br>
    <b>쿠키의 설치/운영 및 거부</b>
  <br>
    이용자들은 쿠키에 대하여 사용여부를 선택할 수 있습니다. 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용할 수도 있고, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 당사 일부 서비스는 이용할 수 없습니다.
  <br>
  <br>
  <b>설정방법</b>
    <ol>
      <li>[도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</li>
      <li>[개인정보 탭]을 클릭합니다.</li>
      <li>[개인정보취급 수준]을 설정하시면 됩니다.</li>
    </ol>
  </p>
  <p>
    <strong>8. 고객 옵션</strong>
  <br><br>
  당사에서 전송하는 모든 전자 우편에 대해 고객은 언제든지 앞으로의 메일을 수신거부 할 수 있습니다. 기존 메일을 실수로 받고 있다고 생각되거나 당사의 프린팅 정보와 메일 전송 정보를 더 이상 받고 싶지 않은 경우 커버레터를 팩스로 전송하면 데이터베이스에서 이름을 삭제해 드립니다.
  </p>
  <p>
    <strong>9. 개인정보관리 책임자 및 담당자</strong>
  <br><br>
  당사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 개인정보관리책임자를 지정하고 있습니다. 고객의 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보관리담당자에게 연락 주시기 바랍니다.
  <br><br>
  [개인정보 관리 담당자]
  <br><br>
  이 름 : 송정훈<br>
  소 속 : Prottype.<br>
  직 위 : 대표<br>
  전화번호 : 010 - 7134 - 5717<br>
  이 메 일 : jhsong@maruglobal.co.kr
  <br><br>
  귀하께서는 당사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리담당자 혹은 담당부서로 신고하실 수 있습니다. 당사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 개인정보침해에 관한 신고나 상담이 필요하신 경우에는 개인정보침해신고센터, 대검찰청 인터넷범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.
  <br><br>
  개인분쟁조정위원회 (www.kopico.go.kr ,1336)<br>
  정보보호마크인증위원회 (www.eprivacy.or.kr)<br>
  대검찰청 인터넷범죄수사센터 (www.spo.go.kr ,02-3480-3600)<br>
  경찰청 사이버테러대응센터 (www.police.go.kr/www/security/cyber.jsp ,02-392-0330)<br>
  <br><br>
  <b>&nbsp;고지의 의무&nbsp;</b>
  <br>
  -&nbsp;법령, 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 변경사항 시행일의 7일 전부터 당사 사이트의 공지사항을 통하여 고지합니다.
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@import '@/assets/css/global';
.privacy-contents {
  padding: 2.083333vw 4.1666666vw; /* 40px, 80px / 1920px * 100 */
}
.privacy-contents hr{
  margin: 0.78125vw 0 1.5625vw 0; /* 15px, 30px / 1920px * 100 */
}
.privacy-contents-headline{
  margin: 0;
  font-size: clamp(30px, 1.666vw, 32px) !important;
  font-weight: 1000;
  letter-spacing: -0.03em;
}
.privacy-contents p {
  text-align: left;
  font-size: 1.04166vw; /* 20px / 1920px * 100 */
  text-indent: 0.5em;
  line-height: min(150%, 43px);
}
.privacy-contents strong {
  font-size: 1.19791666vw; /* 23px / 1920px * 100 */ 
}
.privacy-contents li {
  text-align: left;
  font-size: 0.9375vw; /* 18px / 1920px * 100 */
  line-height: min(150%, 43px);
}
</style>