<template>
  <div class="modal-bg" v-if="this.$store.getters.getCheckPasswordModal">
    <div class="modal-inner">
      <p class="headline">비밀번호 확인</p>
      <p class="basic-grey">가입시 입력한 비밀번호를 확인합니다.</p>
      <input class="modal-input" type="password" :value="password" @input="setPassword">
      <div class="button-wrapper">
        <div class="modal-submit" @click="checkPassword">완료</div>
        <div class="modal-close" @click="closeModal">취소</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import store from '@/store';
import axios from 'axios';
export default {
    setup(props,{ emit }){
        const password = ref('');
        const success = ref(false);
        const setPassword = (event) => {
          password.value = event.target.value;
        }
        const checkPassword = async () => {
            try {
                const res = await axios.post(`/account/checkPassword`,{
                    inputPassword : password.value,
                },
                {
                  headers: {
                    Authorization: localStorage.getItem('accessToken')
                  },
                })

                if (res.status === 200){
                    success.value = true;
                    emit('success',success.value);
                    closeModal();
                    alert("비밀번호확인에 성공했습니다.");
                }

            } catch (error) {
                alert("비밀번호를 확인해주세요.");
            }
        }

        const closeModal = () => {
        store.commit('checkPasswordModalStateChange', false);
        }

        return {
            checkPassword,
            closeModal,
            setPassword,
        }
    }
}
</script>

<style scoped>
@import '@/assets/css/global';
</style>