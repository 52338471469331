import axios from 'axios';
import store from '@/store';

const checkToken = async () => {

    if(localStorage.getItem("accessToken")){
        const accessToken = localStorage.getItem("accessToken").split(" ")[1];
        try {
            const res = await axios.post(`/account/checkAccessToken`,{
                accessToken : accessToken,
                }
            );

            if(res.data.result) {
                return res.data.userRole
            } else {
                localStorage.removeItem("accessToken")
                store.commit('adminNavi', false);
                store.commit("signout")
                return res.data.result
            }
            
        } catch {
            localStorage.removeItem("accessToken")
            store.commit('adminNavi', false);
            store.commit("signout")
            return false;
        }
    } else {
        localStorage.removeItem("accessToken")
        store.commit('adminNavi', false);
        store.commit("signout")
        return false;
    }
}

const checkLogin = async () => {
    if(localStorage.getItem("accessToken")){
        const accessToken = localStorage.getItem("accessToken").split(" ")[1];
        try {
            const res = await axios.get(`/account/tokenValidate`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
        })
            return res.data;
        } catch(error) {
            console.log(error);
        }
    }else{
        return false;
    }
}

export default {
    checkToken,
    checkLogin
}