const AdminNotice = [
    {
        path: '/admin/notice',
        name: 'AdminNotice',
        component: () => import(/* webpackChunkName: "adminNotice" */ '@/pages/admin/adminNotice/adminNotice.vue')
    },
    {
        path: '/admin/notice/write',
        name: 'AdminNoticeWrite',
        component: () => import(/* webpackChunkName: "adminNotice" */ '@/pages/admin/adminNotice/adminNoticeWrite.vue')
    },
    {
        path: '/admin/notice/read/:noticeNo',
        name: 'AdminNoticeRead',
        component: () => import(/* webpackChunkName: "adminNotice" */ '@/pages/admin/adminNotice/adminNoticeRead.vue')
    },
    {
        path: '/admin/notice/update/:noticeNo',
        name: 'AdminNoticeUpdate',
        component: () => import(/* webpackChunkName: "adminNotice" */ '@/pages/admin/adminNotice/adminNoticeUpdate.vue')
    },
]

export default AdminNotice;