const AdminPortfolio = [
    {
        path: '/admin/portfolio',
        name: 'AdminPortfolio',
        component: () => import(/* webpackChunkName: "adminPortfolio" */ '@/pages/admin/adminPortfolio/adminPortfolio')
    },
    {
        path: '/admin/portfolio/write',
        name: 'AdminPortfolioWrite',
        component: () => import(/* webpackChunkName: "adminPortfolio" */ '@/pages/admin/adminPortfolio/adminPortfolioWrite.vue')
    },
    {
        path: '/admin/portfolio/read/:portfolioNo',
        name: 'AdminPortfolioRead',
        component: () => import(/* webpackChunkName: "adminPortfolio" */'@/pages/admin/adminPortfolio/adminPortfolioRead.vue')
    },
    {
        path: '/admin/portfolio/update/:portfolioNo',
        name: 'AdminPortfolioUpdate',
        component: () => import(/* webpackChunkName: "adminPortfolio" */'@/pages/admin/adminPortfolio/adminPortfolioUpdate.vue')
    },

]

export default AdminPortfolio;