<template>
  <div class="home-notice-list-container">
    <div class="home-notice-empty" v-if="noticeList.length == 0">
        <p class="home-notice-headline">
            공지사항이 없습니다.
        </p>
    </div>
    <div v-else class="home-notice-list-box" v-for="notice in noticeList" :key="notice.noticeNo">
        <router-link class="home-notice-wraper" :to="{ name: 'UserNoticeRead', params: { noticeNo: notice.noticeNo } }">
            <p class="home-notice-date">{{ notice.noticeUpdateTime ? notice.noticeUpdateTime + " 수정" : notice.noticeWriteTime }}</p>
            <p class="home-notice-title">{{ notice.noticeTitle }}</p>
            <p class="home-notice-content">{{ notice.noticeContent.length > 30 ? notice.noticeContent.substring(0, 30) + '...' : notice.noticeContent }}</p>
        </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref, onMounted } from 'vue';

export default {
    setup() {
        const boardTypeNo = ref(2)
        const noticeLimit = ref(4);

        const noticeList = ref([]);
        const getNoticeListHome = async () => {

            try {
                const res = await axios.get(`/notice/listForHome/${boardTypeNo.value}/${noticeLimit.value}`);
                noticeList.value = res.data;

            } catch (error) {
                console.log(error);
            }
        }

    onMounted(() => {
        getNoticeListHome();
    })

        return {
            noticeList
        }

    }
}
</script>

<style scoped>
.home-notice-list-container {
    display: flex;
    flex-wrap: wrap;

    gap: 0.520833vw 0.520833vw; /* 10px / 1920px * 100 */

    padding: 0.78125vw; /*  15px / 1920px * 100 */
    width: 100%;

    justify-content: center;
    align-items: center;

    pointer-events: auto;

}

.home-notice-list-box {

    position: relative;
    width: 35%;

    padding: 1.8229166vw; /* 35px / 1920px * 100 */

    background-color: rgba(255, 255, 255, 0.8);
    text-align: left;

    border: 1px dashed rgb(180, 180, 180);
    border-radius: 3px;
    box-shadow: 1px 1px 3px rgb(210, 210, 210);

    overflow: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease;
    /* opacity: 0.7; */
}

.home-notice-list-box::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    
    transform: translate(-50%, -50%);
    transition: width 0.7s ease, height 0.7s ease;

    pointer-events: none; /* ::after 요소 위의 클릭을 부모 요소로 통과시킴 */

}

.home-notice-list-box:hover::after {
    width: 110%;
    height: 110%;
    background: rgba(189, 189, 189, 0.5);
    z-index: -999;

}

.home-notice-date {
    margin: 0 0 1rem 0;
    font-size: 0.9375vw; /* 18px / 1920px * 100 */
    color: rgb(140, 140, 140);
}

.home-notice-title {
    margin: 0 0 0.6667rem 0; /* 18px / 27px */
    font-size: 1.40625vw; /* 27px / 1920px * 100 */
    font-weight: 600;

}

.home-notice-content {
    margin: 0;
    font-size: 0.9375vw; /* 18px / 1920 px * 100 */
    line-height: 130%;
}

.home-notice-empty {
    display: flex;

    align-items: center;
    justify-content: center;

    width: 80%;
    height: 26.0417vw; /*31.25vw;*/ /* 600px / 1920px * 100 */

    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    box-shadow: 1px 1px 3px rgba(195, 195, 195);
}

.home-notice-headline {

    padding: 0.26042vw; /* 5px / 1920px * 100 */
    margin: 0.52083vw; /* 10px / 1920px * 100 */

    font-size: 2.60417vw; /* 50px / 1920px * 100 */
    font-weight: 900;

    color: rgba(140, 140, 140, 0.5);
}

</style>