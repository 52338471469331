<template>
  <div>
    <div>
        <img 
            v-for="(url, index) in imgUrl" 
            :key="index" 
            :src="url" 
            v-show="index === activeIndex"
            class="slide"
        >
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
export default {
    setup(){
        
        const imgUrl = ref([
            require('@/assets/imgSlide/slide01.png'),
            require('@/assets/imgSlide/slide02.png'),
            require('@/assets/imgSlide/slide03.jpg'),
            require('@/assets/imgSlide/slide04.jpg'),
            require('@/assets/imgSlide/slide05.jpg')
        ]);

        const activeIndex = ref(0);
        let intervalId;

        const advanceSlide = () => {
            activeIndex.value = (activeIndex.value + 1) % imgUrl.value.length;
        };

        onMounted(() => {
            intervalId = setInterval(advanceSlide, 2000);
        });

        onUnmounted(() => {
            clearInterval(intervalId);
        });

        return{
            imgUrl,
            activeIndex
        }
    }

}
</script>

<style scoped>
.slider-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 75%;
    transition: opacity 0.5s ease-in-out;
        transform: translateX(0%); /* 초기 위치 */
    z-index: -999999;
}

/* 다른 이미지는 숨김 */
.slide[v-show="false"] {
    display: none;
}
</style>