import CheckToken from '@/axioses/checkToken';
const UserRequest = [
    {
        path: '/user/request',
        name: 'UserRequest',
        component: () => import(/* webpackChunkName: "request" */ '@/pages/user/userRequest/userRequest.vue')
    },
    {
        path: '/user/request/userRequestSTP',
        name: 'UserRequestSTP',
        component: () => import(/* webpackChunkName: "request" */ '@/pages/user/userRequest/userRequestSTP.vue')
    },
    {
        path: '/user/request/userRequestETC',
        name: 'UserRequestETC',
        component: () => import(/* webpackChunkName: "request" */ '@/pages/user/userRequest/userRequestETC.vue')
    },
    {
        path: '/user/request/userRequestList',
        name: 'UserRequestList',
        component: () => import(/* webpackChunkName: "request" */ '@/pages/user/userRequest/userRequestList.vue'),
        beforeEnter: async (to, from, next) => {
            try {
                const tokenRole = await CheckToken.checkLogin();
                if (tokenRole) {
                    next();
                } else {
                    alert('권한이 없습니다.');
                    next('/user/account/login');
                }
            } catch (error) {
                console.error('Error during token validation:', error);
                next('/user/account/login');
            }
        }
    },
    {
        path: '/user/request/read/:requestNo',
        name: 'UserRequestRead',
        component: () => import(/* webpackChunkName: "request" */ '@/pages/user/userRequest/userRequestRead.vue')
    },
    {
        path: '/user/request/update/:requestNo',
        name: 'UserRequestUpdate',
        component: () => import(/* webpackChunkName: "request" */ '@/pages/user/userRequest/userRequestUpdate.vue')
    },
]

export default UserRequest;