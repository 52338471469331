<template>
  <div class="container"> 
    <div class="admin-main-container">
        <div class="board-indicator">
            <p class="headline">관리자 메인</p>
        </div>
        <div class="admin-main-grid-container">
            <div class="admin-main-box a">
                <p class="basic">오늘 방문객 수 : <span class="info-today">{{ dailyVisitor }}</span>명</p>
            </div>
            <div class="admin-main-box b">
                <p class="basic">새로 등록된 공지사항 :
                    <span class="info-today">{{ dailyNewNotice }}</span> 개
                </p>
            </div>
            <div class="admin-main-box c">
                <p class="basic-bold">새 문의사항</p>
                <p class="basic">오늘 <span class="info-today">{{ newRequestCount }}</span> 개 (월 <span class="info-today">{{ monthlyRequestCount }}</span> 개)</p>
            </div>
            <div class="admin-main-box d">
                <p class="basic-bold">신규회원</p>
                <p class="basic">오늘 <span class="info-today">{{ dailyNewAccountCount }}</span> 명 (월 <span class="info-today">{{ monthlyNewAccountCount }}</span> 명)</p>
            </div>
            <div class="admin-main-box e">
                <p class="basic-bold">새 포트폴리오</p>
                <div class="admin-main-portfolio-container">
                    <div v-if="newPortfolioList.length == 0">
                        <p class="basic">새로 작성된 포트폴리오가 없습니다.</p>
                    </div>
                    <div class="admin-main-portfolio-wrapper" v-else >
                        <router-link 
                            class="admin-main-portfolio" 
                            :to="{ name: 'AdminPortfolioRead', params: { portfolioNo: newPortfolio.portfolioNo } }"
                            v-for="newPortfolio in displayedPortfolios" :key="newPortfolio.portfolioNo"
                        >
                            <p class="basic">{{ newPortfolio.portfolioTitle }}</p>
                            <p class="basic">{{ newPortfolio.portfolioWriter }}</p>
                            <p class="basic">
                                {{
                                    new Date(newPortfolio.portfolioUpdateTime || newPortfolio.portfolioWriteTime).getMonth() + 1 + 
                                    '/' + 
                                    new Date(newPortfolio.portfolioUpdateTime || newPortfolio.portfolioWriteTime).getDate() + 
                                    (newPortfolio.portfolioUpdateTime ? ' 수정' : '')
                                }}
                            </p>
                        </router-link>
                    </div>
                    <div v-if="newPortfolioList.length > 3">
                        <p class="admin-main-toggle" @click="toggleShowAll">
                            {{ showAll ? '접기' : '더보기' }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="admin-main-box f">
                <p class="basic-bold">현재 진행중인 답글</p>
                <div class="admin-main-reply">
                    
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';
export default {
    setup(){
        const dailyVisitor = ref(0);
        const dailyNewNotice = ref(0);
        const newRequestCount = ref(0);
        const monthlyRequestCount = ref(0);
        const dailyNewAccountCount = ref(0);
        const monthlyNewAccountCount = ref(0);
        const newPortfolioList = ref([]);
        const showAll = ref(false);

        const getDailyVisitor = async () => {
            const res = await axios.get('/account/getDailyVisitorCount')
            dailyVisitor.value = res.data;
        }

        const getNewNoticeCount = async () => {
            const res = await axios.get('/admin/notice/getNewNoticeCount')
            dailyNewNotice.value = res.data;
        }

        const getNewRequestCount = async () => {
            const res = await axios.get('/admin/request/getNewRequestCount')
            newRequestCount.value = res.data;
        }

        const getMonthlyRequestCount = async () => {
            const res = await axios.get('/admin/request/getMonthlyRequestCount')
            monthlyRequestCount.value = res.data;
        }

        const getNewPortfolio = async () => {
            const res = await axios.get('/admin/portfolio/getNewPortfolio')
            newPortfolioList.value = res.data;
        }

        const displayedPortfolios = computed(() => {
            return showAll.value ? newPortfolioList.value : newPortfolioList.value.slice(0, 3);
        });

        const toggleShowAll = () => {
            showAll.value = !showAll.value;
        };

        const getDailyNewAccount = async () => {
            const res = await axios.get('/admin/account/getDailyNewAccountCount')
            dailyNewAccountCount.value = res.data;
        }

        const getMonthlyNewAccount = async () => {
            const res = await axios.get('/admin/account/getMonthlyNewAccountCount')
            monthlyNewAccountCount.value = res.data;
        }

        onMounted(() => {
            getDailyVisitor();
            getNewNoticeCount();
            getNewRequestCount();
            getMonthlyRequestCount();
            getNewPortfolio();
            getDailyNewAccount();
            getMonthlyNewAccount();
        });
    return{
        dailyVisitor,
        dailyNewNotice,
        newRequestCount,
        monthlyRequestCount,
        newPortfolioList,
        displayedPortfolios,
        toggleShowAll,
        showAll,
        dailyNewAccountCount,
        monthlyNewAccountCount,
    }

    }
}
</script>

<style scoped>
@import '@/assets/css/global';
@import '@/assets/css/home/adminMain.css';
</style>