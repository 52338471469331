const UserAccountRoutes = [
    {
        path: '/user/account/login',
        name: 'UserAccountLogin',
        component: () => import(/* webpackChunkName: "account" */ '@/pages/user/userAccount/userLogin')
    },
    {
        path: '/user/account/userRegistration',
        name: 'UserRegistration',
        component: () => import(/* webpackChunkName: "account" */ '@/pages/user/userAccount/userRegistration')
    },
    {
        path: '/user/account/userInfo',
        name: 'UserInfo',
        component: () => import(/* webpackChunkName: "account" */ '@/pages/user/userAccount/userInfo'),
    },
    {
        path: '/user/account/findIdPw',
        name: 'UserAccountIdPw',
        component: () => import(/* webpackChunkName: "account" */ '@/pages/user/userAccount/userAccountFindIdPw.vue'),
    },
]

export default UserAccountRoutes;