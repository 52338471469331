const UserReply = [
    // {
    //     path: '/user/reply',
    //     name: 'UserRequest',
    //     component: () => import('@/pages/user/userRequest/userRequest.vue')
    // },
    {
        path: '/user/reply/userReplyWrite/:requestNo/:requestTypeNo',
        name: 'UserReplyWrite',
        component: () => import(/* webpackChunkName: "reply" */ '@/pages/user/userReply/userReplyWrite')
    },
    // {
    //     path: '/user/reply/userReplyList',
    //     name: 'UserReplyList',
    //     component: () => import('@/pages/user/userRequest/userReplyList.vue')
    // },
    {
        path: '/user/reply/read/:requestNo/:replyNo',
        name: 'UserReplyRead',
        component: () => import(/* webpackChunkName: "reply" */ '@/pages/user/userReply/userReplyRead.vue')
    },
    {
        path: '/user/reply/update/:replyNo',
        name: 'UserReplyUpdate',
        component: () => import(/* webpackChunkName: "reply" */ '@/pages/user/userReply/userReplyUpdate.vue')
    },
]

export default UserReply;