<template>
    <div class="footer-container">
        <CompanyInfo />
    </div>
</template>

<script>
import CompanyInfo from './footer/companyInfo.vue'

export default {

    components:{
        CompanyInfo,
    }

}
</script>

<style>
.footer-container{
    width: 100%;

    border-top: 1px solid black;
}
</style>