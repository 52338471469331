<template>
  <div class="navigationBar-container">
    <ul>
        <li class="navigationBar-logo">
            <router-link :to="{name: 'Home'}">
                <img src="@/assets/prottypeLogoNavi.svg">
            </router-link>
        </li>

        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu portfolio">
            <router-link :to="{name: 'AdminPortfolio'}">
                <span 
                    @mouseover="changeNeviMenu('portfolio', 'Portfolio')" 
                    @mouseleave="resetNeviMenu('portfolio', '포트폴리오관리')"
                >
                    {{ neviMenu.portfolio }}
                </span>
            </router-link>
        </li>
        
        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu process">
            <router-link :to="{name: 'AdminAccount'}">
                <span 
                    @mouseover="changeNeviMenu('user', 'Account')" 
                    @mouseleave="resetNeviMenu('user', '회원관리')"
                >
                    {{ neviMenu.user }}
                </span>
            </router-link>
        </li>
        
        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu notice">
            <router-link :to="{name: 'AdminNotice'}">
                <span 
                    @mouseover="changeNeviMenu('notice', 'Notice')" 
                    @mouseleave="resetNeviMenu('notice', '공지사항관리')"
                >
                    {{ neviMenu.notice }}
                </span>
            </router-link>
        </li>
        
        <li><span class="navigationBar-separator"></span></li>

        <li class="navigationBar-menu request">
            <router-link :to="{name: 'AdminRequest'}">
                <span 
                    @mouseover="changeNeviMenu('request', 'Request')" 
                    @mouseleave="resetNeviMenu('request', '견적문의관리')"
                >
                    {{ neviMenu.request }}
                </span>
            </router-link>
        </li>
        
        <li><span class="navigationBar-separator"></span></li>
        
        <li class="navigationBar-menu siginbox">
            <span class="navigationBar-signed" @click="moveAdminPage">관리자홈</span>
            <span class="navigationBar-signed" @click="logout">로그아웃</span>
        </li>
    </ul>
    <CheckPassword @success = 'moveAdminPage' />
  </div>
</template>

<script>
import accountModule from '@/axioses/accountModule'
import { ref } from 'vue';
import CheckPassword from '../checkPassword/checkPassword.vue';
import store from '@/store';
import router from '@/router';
export default {
    components: {
        CheckPassword,
    },
    
setup(){

    const logout = accountModule.logout;
    const admin = ref(false);
    const neviMenu = ref({
        portfolio: '포트폴리오관리',
        user: '회원관리',
        notice: '공지사항관리',
        request: '견적문의관리',
    });

    const changeNeviMenu = (menu, newValue) => {
        neviMenu.value[menu] = newValue;
    };

    const resetNeviMenu = (menu, originalValue) => {
        neviMenu.value[menu] = originalValue;
    };

    const openModal = () => {
        store.commit('checkPasswordModalStateChange', true);
    }

    const moveAdminPage = (success) => {
        if(success){
            router.push('/admin');
        }
    }

    return{
        logout,
        neviMenu,
        changeNeviMenu,
        resetNeviMenu,
        admin,
        openModal,

        moveAdminPage,
    }
}
}
</script>

<style scoped>
@import '@/assets/css/navigation/navigationBarOriginal.css';
</style>