const AdminRequest = [
    {
        path: '/admin/request',
        name: 'AdminRequest',
        component: () => import(/* webpackChunkName: "adminRequest" */ '@/pages/admin/adminRequest/adminRequest.vue')
    },
    {
        path: '/admin/request/adminUnansweredRequest',
        name: 'AdminUnansweredRequest',
        component: () => import(/* webpackChunkName: "adminRequest" */ '@/pages/admin/adminRequest/adminUnansweredRequest.vue')
    },
    {
        path: '/admin/request/adminTotalRequest',
        name: 'AdminTotalRequest',
        component: () => import(/* webpackChunkName: "adminRequest" */ '@/pages/admin/adminRequest/adminTotalRequest.vue')
    },
    {
        path: '/admin/request/read/:requestNo',
        name: 'AdminRequestRead',
        component: () => import(/* webpackChunkName: "adminRequest" */ '@/pages/admin/adminRequest/adminRequestRead.vue')
    },
    // {
    //     path: '/admin/request/update/:requestNo',
    //     name: 'UserRequestUpdate',
    //     component: () => import(/* webpackChunkName: "adminRequest" */ '@/pages/admin/userRequest/userRequestUpdate.vue')
    // },
]

export default AdminRequest;