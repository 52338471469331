<template>
  <div class="container">
    <div class="terms-contents">
      <p class="terms-contents-headline">이용약관</p>
      <hr>
      <p>
        <strong>제1조 (목적)</strong>
        <br><br>
        본 약관은 PROTTYPE.(프롯타입)과 PROTTYPE.(이하 "당사")이 운영하는 인터넷 웹사이트 www.prottype.com (이하 "사이트")을 통해 제공하는 인터넷 관련 서비스(이하 "서비스")의 이용조건 및 절차에 관련하여 회사와 이용자간의 권리와 의무 및 기타 필요한 사항을 규정함을 목적으로 합니다.
      </p>
      <p>
        <strong>제2조 (약관의 명시와 효력 및 변경)</strong>
        <br><br>
        이 약관의 내용과 주소지, 연락처(전화, 전자우편주소 등) 등을 이용자가 알 수 있도록 당 사이트의 초기화면(전면)에 게시합니다.
        <br>
        이 약관은 이용자가 약관에 대해 동의를 한 후 회원가입 절차에 의하여 회사에서 필요한 개인정보를 입력한 후 바로 적용됩니다.
        <br>
        당사는 서비스 운영 및 영업상의 중요한 사유로 인하여 약관을 변경할 수 있으며, 변경된 약관은 이메일 발송 및 공지등록(7일 이상) 등의 방법을 통해 사전에 통지합니다.
        <br>
        당 사이트 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)을 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
        </p>
        <p>
        <strong>제3조 (약관의 준칙)</strong>
        <br><br>
        이 약관에 명시되지 않은 사항에 대해서는 관계법령의 규정에 따르며 그 외의 경우에는 상호 협의하여 조정하도록 합니다.
      </p>
      <p>
        <strong>제4조 (서비스의 변경 및 중단)</strong>
        <br><br>
        당사는 천재지변 등의 자연재해 및 영업상의 사정으로 인하여 서비스를 변경하거나 중단할 수 있으며 이에 대하여 이메일 발송 및 공지등록(3일 이상) 등을 통하여 사전에 통지합니다.
      </p>
      <p>
        <strong>제5조 (용어의 정의)</strong>
        <br><br>
        이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br>
        <ol>
          <li>회원 : 서비스 이용을 신청하고 본사는 이를 승낙하여 회원ID를 발급받은 회원(이하 '회원'이라 한다)을 뜻합니다.</li>
          <li>회원ID : 회원의 식별과 서비스 이용을 위하여 회원이 지정한 영문과 숫자의 조합을 회원아이디로 정합니다.</li>
          <li>회원비밀번호 : 회원과 회원ID가 일치하는지를 확인하고 통신상의 회원이 등록한 자료의 비밀보호를 위하여 로그인 시에 회원ID와 더불어 확인되는 영문대소문자, 숫자, 특수문자의 조합으로 회원이 정하고 당사가 부여합니다.</li>
          <li>해지 : 회사 또는 회원이 서비스 개통 후에 이용계약을 종료시키는 행위를 말합니다.</li>
          <li>기본정보 : 서비스에서 제공하는 정보 중 부가정보 이외의 정보를 말합니다.</li>
          <li>이용시간 : 서비스를 이용한 시작시각부터 이용 종료시각까지의 시간을 말합니다.</li>
        </ol>
      </p>
      <p>
        <strong>제6조 (회원가입의 성립)</strong>
        <br><br>
        회원가입은 이용자의 이용신청에 대한 회사의 이용승낙과 이용자의 약관내용에 대한 동의를 통해 성립합니다.
        서비스를 이용하고자 할 때에는 회원가입 절차를 통해 당사에서 요구하는 일정양식의 회원정보를 사실에 근거하여 입력하여야 합니다.
        당사는 다음 각 호에 해당하는 회원가입에 대하여는 이를 승낙하지 않으며 향후 발견 시에는 당사가 임의로 회원을 탈퇴 시킬 수 있습니다.
        <br>
        <ol>
          <li>본인의 실명이 아닌 다른 사람의 명의를 사용하여 가입한 경우</li>
          <li>같은 사람이 이중으로 가입한 경우</li>
          <li>회원정보 내용을 허위로 기재한 경우</li>
          <li>사회의 안녕 및 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우</li>
          <li>관리책임자의 판단에 따라 문제가 발생할 우려가 있는 경우</li>
        </ol>
      </p>
      <p>
        <strong>제7조 (서비스의 이용)</strong>
        <br><br>
        기타 누락된 내용은 소프트웨어재산권보호위원회의 내용에 따릅니다.
        <br>
        <ol>
          <li>서비스는 1년 365일 1일 09:30 ~ 16:30 (공휴일, 토요일, 일요일 제외) 지정된 기간에 제공하는 것을 원칙으로 합니다.</li>
          <li>서비스의 안정적인 제공을 위한 시스템 점검 및 유지보수 등이 필요할 경우에는 서비스를 일시적으로 중단하거나 서비스 시간을 제한할 수 있으며 회원들에게 이에 대한 공지를 사전에 합니다.</li>
          <li>천재지변 및 자연재해로 인한 서비스의 장애에 대하여 당사는 항상 대비책을 강구하나 부득이 이로 인한 서비스의 중단, 지연, 자료의 손실이 발생하였을 경우에 당사는 이에 대한 배상의 책임을 지지 않습니다.</li>
          <li>국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때에는 서비스 제공을 중지하거나 제한할 수 있습니다.</li>
          <li>이용자가 정식적인 서비스의 범위를 벗어나 개인의 영리를 추구하는 경우에는 임의적으로 해당 회원을 탈퇴시킬 수 있습니다.</li>
          <li>이용자가 명백한 사유 없이 이용자 회원ID 변경을 원할 경우에는 그 변경을 제한합니다.</li>
        </ol>
      </p>
      <p>
        <strong>제8조 (당사의 의무)</strong>
        <br>
        <ol>
          <li>이 약관에 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.</li>
          <li>특정 개인을 식별할 수 없는 형태로 제공하는 통계적 정보 이외의 회원정보를 회원의 승낙 없이 타인에게 누설, 또는 배포할 수 없습니다. 단, 전기 통신관련 법령 등 관계 법령에 의하여 관계기관 등의 요구가 있을 경우에는 제외합니다.</li>
          <li>회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하며 즉시 처리가 곤란한 경우에는 회원에게 그 사유와 처리 일정을 통보합니다.</li>
          <li>서비스의 효율적인 관리와 원활한 운영을 위하여 서비스 운영자를 둡니다.</li>
        </ol>
      </p>
      <p>
        <strong>제9조 (회원의 의무)</strong>
        <br>
        <ol>
          <li>회원은 자신의 ID와 비밀번호에 관한 관리의 책임을 갖습니다.</li>
          <li>회원 ID의 변경은 정당한 사유가 없을 때는 불가능합니다. 이를 제외한 회원정보의 변경은 회원정보 수정화면을 통하여 수시로 변경할 수 있습니다.</li>
          <li>회원정보의 부정확함으로 인한 모든 불이익에 대한 책임은 회원 개인에게 있습니다.</li>
          <li>회원이 자신의 회원 ID가 부정하게 사용된 사실을 발견하였을 때에는 반드시 그 사실을 당사에 통보하여야 합니다.</li>
          <li>회원은 서비스를 통해 인권침해 및 타인에게 피해를 주는 행위를 하여서는 안되며 이에 따른 책임은 모두 회원 본인에게 있습니다.</li>
          <li>회원은 이 약관 및 관계법령[전기통신법 제53조와 전기통신사업법 시행령 16조(불온통신), 통신사업법 제53조 3항 등]에서 규정한 사항을 준수하여야 합니다.</li>
          <li>서비스를 이용하여 음란물이나 불온한 내용을 전송함으로써 발생하는 모든 법적인 책임은 'ID사용자'에게 있으며 서비스를 제공하는 당사는 책임지지 않습니다.</li>
        </ol>
      </p>
      <p>
        <strong>제10조 (계약변경 및 탈퇴)</strong>
        <br><br>
        서비스 이용 해지를 원할 경우에는 회원 본인이 직접 당사에 회원탈퇴 신청을 하거나 홈페이지 상에 있는 회원탈퇴를 통하여 당사에 요청을 해야 합니다.
      </p>
      <p>
        <strong>제11조 (서비스 이용제한)</strong>
        <br><br>
        당사는 회원이 다음 사항에 해당하는 행위를 하였을 경우 이를 해당 회원에게 사전 통지하여 소명의 기회를 부여한 후 강제 탈퇴 시킬 수 있습니다.
        <br>
        <ol>
          <li>공공질서 및 미풍양속에 반하는 행위를 한 경우</li>
          <li>범죄적 행위에 관련되는 경우</li>
          <li>타인의 회원 ID 및 비밀번호를 도용한 경우</li>
          <li>타인의 명예를 손상시키거나 불이익을 준 경우</li>
          <li>같은 사용자가 다른 ID로 이중 회원등록을 한 경우</li>
          <li>서비스에 위해를 가하는 등 서비스의 건전한 이용을 저해하는 경우, 이때 피해자가 원하는 경우 관련 법규에 의한 법적 조치도 가능합니다.</li>
          <li>관리책임자의 판단에 따라 문제가 발생할 우려가 있는 경우</li>
        </ol>
      </p>
      <p>
        <strong>제12조 (이용자의 게시물 관리)</strong>
        <br><br>
        당사는 이용자가 게시하거나 등록하는 서비스 내의 내용물이 다음 각 사항에 해당된다고 판단되는 경우에 사전통지 없이 삭제할 수 있습니다.
        <br>
        <ol>
          <li>공공질서 및 미풍양속에 위반되는 내용</li>
          <li>제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
          <li>다른 이용자 또는 제 3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용</li>
          <li>범죄적 행위에 결부된다고 인정되는 내용</li>
          <li>기타 관계 법령이나 회사에서 정한 규정에 위반되는 경우</li>
          <li>관리책임자의 판단에 따라 문제가 발생할 우려가 있는 경우</li>
        </ol>
      </p>
      <p>
        <strong>제13조 (정보의 제공 및 영리목적 광고 전송)</strong>
        <br>
        <ol>
          <li>당사는 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서는 전자우편 등의 다양한 방법으로 회원에게 제공할 수 있습니다. 단, 수취를 거부한 회원에게는 제공하지 않습니다.</li>
          <li>견적의뢰 및 이벤트 신청으로 접수된 회원은 영리목적 광고성 정보가 전자우편으로 제공될 수 있습니다. 단, 수취를 거부한 회원에게는 제공하지 않습니다.</li>
        </ol>
      </p>
      <p>
        <strong>제14조 (게시물의 저작권)</strong>
        <br>
        <ol>
          <li>게시물에 대한 권리와 책임은 게시자 본인에게 있으며 당사는 게시자의 동의 없이는 이를 영리적인 목적으로 사용할 수 없습니다. 단, 비영리적인 경우에는 제외되며 서비스 내의 게재권을 갖습니다.</li>
          <li>회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.</li>
        </ol>
      </p>
      <p>
        <strong>제15조 (사전통지방법)</strong>
        <ol>
          <li>개인별 회원에 대한 통지를 하는 경우, 회원이 등록한 이메일주소를 통하여 통지를 합니다.</li>
          <li>불특정 다수 회원에 대한 통지의 경우 1주일 이상 게시판에 공지함으로서 개별통지에 갈음할 수 있습니다.</li>
        </ol>
      </p>
      <p>
        <strong>제16조 (면책조항)</strong>
        <br>
        <ol>
          <li>당사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
          <li>당사는 회원의 귀책사유로 인한 서비스 이용상의 장애에 대하여 책임을 지지 않습니다.</li>
          <li>당사는 회원이 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</li>
          <li>당사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.</li>
          <li>당사에서 제공하는 제반서비스를 사용 시 서비스 제공 범위를 벗어나 상업적으로 사용할 수 없습니다.</li>
        </ol>
      </p>
      <p>
        <strong>제17조 (분쟁해결)</strong>
        <br>
        <ol>
          <li>당사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 처리하기 위하여 불만처리기구를 설치하여 운영합니다.</li>
          <li>당사는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통보해 드립니다.</li>
          <li>당사와 회원간에 발생한 분쟁은 관계 법령에 따르며 전자거래 분쟁조정위원회의 조정에 따를 수 있습니다.</li>
        </ol>
      </p>
      <p>
        <strong>제18조 (재판권 및 준거법)</strong>
        <br>
        <ol>
          <li>당사와 회원간에 이용자간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.</li>
          <li>당사와 회원간에 제기된 전자거래 소송에는 한국법을 적용합니다.</li>
        </ol>
      </p>
      <p>
        <strong>부칙</strong>
        <br>
        <ol>
          <li>성폭력 범죄의 처벌 및 피해자 보호 등에 관한 법률 제14조(통신 매체 이용 음란행위)자기 또는 다른 사람의 성적 욕망을 만족시킬 목적으로 전화, 우편, 컴퓨터 기타 통신매체를통하여 성적 수치심이나 혐오감을 일으키는 말이나 음향, 글이나 도화, 영상 또는 물건을 상대방에게 도달하게 한 자는 1년 이하의 징역, 또는 300만원 이하의 벌금에 처한다.</li>
        </ol>
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@import '@/assets/css/global';
.terms-contents {
  padding: 2.083333vw 4.1666666vw; /* 40px, 80px / 1920px * 100 */
}
.terms-contents hr{
  margin: 0.78125vw 0 1.5625vw 0; /* 15px, 30px / 1920px * 100 */
}
.terms-contents-headline{
  margin: 0;
  font-size: clamp(30px, 1.666vw, 32px) !important;
  font-weight: 1000;
  letter-spacing: -0.03em;
}
.terms-contents p {
  text-align: left;
  font-size: 1.04166vw; /* 20px / 1920px * 100 */
  text-indent: 0.5em;
  line-height: min(150%, 43px);
}
.terms-contents strong {
  font-size: 1.19791666vw; /* 23px / 1920px * 100 */ 
}
.terms-contents li {
  text-align: left;
  font-size: 0.9375vw; /* 18px / 1920px * 100 */
  line-height: min(150%, 43px);
}
</style>