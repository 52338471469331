import axios from 'axios';
import router from '@/router';
import store from '@/store'

const logout = async () => {
    try{

        await axios.delete("/account/logout")

        store.commit('signout');
        store.commit('checkAdmin', false);
        store.commit('adminNavi', false);
        localStorage.clear();
        sessionStorage.clear();
        router.push({path: "/"});

        alert('로그아웃 되었습니다.');

    } catch (error) {
        alert('로그아웃 되었습니다.');
    }
}

export default {
    logout
}