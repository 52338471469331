const UserNotice = [
    {
        path: '/user/notice',
        name: 'UserNotice',
        component: () => import(/* webpackChunkName: "notice" */ '@/pages/user/userNotice/userNotice.vue')
    },
    {
        path: '/user/notice/read/:noticeNo',
        name: 'UserNoticeRead',
        component: () => import(/* webpackChunkName: "notice" */ '@/pages/user/userNotice/userNoticeRead.vue')
    },
]

export default UserNotice;