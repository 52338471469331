<template>
    <div class="router-view-container">
        <router-view />
    </div>
</template>

<script>
export default {
}
</script>

<style>
.router-view-container{
    width: 100%;
}
</style>