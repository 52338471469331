import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    state: {
        signinStatus : false,
        checkPasswordModalState : false,
        splashState : false,
        admin : false,
        openAdminNavi : false,
        requestNo : null,
        currentHomeSection : null,
        emailVerificationModalState : false,
        setNewPasswordModalState : false
    },

    getters: {
        getCheckPasswordModal: (state) => {
            return state.checkPasswordModalState;
        },

        getadminState : (state) => {
            return state.admin;
        },
        
        getopenAdminNavi : (state) => {
            return state.openAdminNavi;
        },

        emailVerificationModal : (state) => {
            return state.emailVerificationModalState;
        },

        setNewPasswordModal : (state) => {
            return state.setNewPasswordModalState;
        }
    },

    mutations : {
        signin : (state) => {
            state.signinStatus = true;
        },

        signout : (state) => {
            state.signinStatus = false;
            localStorage.clear();
        },

        checkPasswordModalStateChange : (state, value) => {
            state.checkPasswordModalState = value;
        },
        checkAdmin : (state, value) => {
            state.admin = value;
        },

        adminNavi : (state, value) => {
            state.openAdminNavi = value;
        },

        saveRequestNo : (state, value) => {
            state.requestNo = value;
        },

        currentHomeSection : (state, value) => {
            state.currentHomeSection = value;
        },

        emailVerificationModalStateChange: (state, value) => {
            state.emailVerificationModalState = value
        },

        setNewPasswordModalStateChange: (state, value) => {
            state.setNewPasswordModalState = value
        }

    },

    plugins: [createPersistedState()],
});