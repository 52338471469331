<template>
  <div class="companyInfo-container">
    <p>
      <strong>
        <router-link class="term-privacy-link" :to="{ name: 'Terms' }">이용약관</router-link >
        /
        <router-link class="term-privacy-link" :to="{ name: 'Privacy' }">개인정보처리방침</router-link>
      </strong>
      <br>
      경기도 화성시 동탄영천로 150(영천동), A - 424<br>
      150, Dongtanyeongcheon-ro, Hwaseong-si, Gyeonggi-do, Republic of Korea
    </p>
    <p>
      T. +82 10 7134 5717<br>
      E. jhsong@maruglobal.co.kr<br>
      Chennel. https://mockupgm.modoo.at/<br>
      © Maru Global & Yoon Deuk Gu
    </p>
    <a href="" target="_blank">
        <img class="companyInfo-icon" src="@/assets/instagram.png" alt="Instagram">
    </a>
    <a href="https://mockupgm.modoo.at/" target="_blank">
        <img class="companyInfo-icon" src="@/assets/modu.png" alt="Modu.at">
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.companyInfo-container{
    display: flex;

    width: 100%;
    height: 7.3437vw; /* 141px / 1920px * 100 */
    max-height: 341px;
    min-height: 141px;

    margin: 0;
    padding: 0 2.057vw 0  2.057vw; /* 39.5px / 1920 * 100 */
    align-items: center;

    

}

.companyInfo-container p{
  margin: 0;
  padding:  2.057vw; /* 39.5px / 1920px * 100  */
  font-weight: 600;
}

.companyInfo-icon{
  padding-left: 1.0417vw;
  width: 4rem;
  height: 4rem;
}

.companyInfo-icon:hover{
  transform: scale(1.05);
}

.term-privacy-link:hover{
  color: rgba(0, 0, 0, 0.3);
}

</style>